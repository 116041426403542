import { combineReducers, createSlice } from '@reduxjs/toolkit'

const sessionUserInitState: {
  name: string
  email: string
  businessName: string
  addressLine1: string
  addressLine2: string
  gstNumber: string
  whatsAppNumber: string
  unreadNotificationCount: number
} = {
  name: '',
  email: '',
  businessName: '',
  addressLine1: '',
  addressLine2: '',
  gstNumber: '',
  whatsAppNumber: '',
  unreadNotificationCount: 0
}

const sessionUser = createSlice({
  name: 'session/user',
  initialState: sessionUserInitState,
  reducers: {
    setSessionUser: (state, { payload }) => {
      return { ...payload }
    },
    setUnreadNotificationCount: (state, { payload }) => {
      return { ...state, unreadNotificationCount: payload }
    },
    clearSessionUser: () => {
      return { ...sessionUserInitState }
    }
  }
})

const sessionToken = createSlice({
  name: 'session/token',
  initialState: '',
  reducers: {
    setToken: (state, { payload }) => {
      return payload
    },
    clearToken: () => {
      return ''
    }
  }
})

// Export actions
export const {
  setSessionUser,
  setUnreadNotificationCount,
  clearSessionUser,
  setToken,
  clearToken
} = {
  ...sessionUser.actions,
  ...sessionToken.actions
}

// Export reducers
export default combineReducers({
  token: sessionToken.reducer,
  user: sessionUser.reducer
})
