import { useSelector } from 'react-redux'
import { RootReducersState } from '../reducers'

const useDevKeyword = () => {
  const devKeywordFlag = useSelector(
    (state: RootReducersState) => state.persist.devKeyword
  )

  return {
    devKeywordFlag: devKeywordFlag === process.env.REACT_APP_DEV_KEYWORD,
    devKeywordText: process.env.REACT_APP_DEV_KEYWORD
  }
}

export default useDevKeyword
