/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import momentTz from 'moment-timezone'
import { saveAs } from 'file-saver'
import { clearSessionUser, clearToken } from '../reducers/session.reducer'
import store from '../reducers/store'
import { DATE_FORMAT, SHORT_DATE_FORMAT, TIME_ZONE } from './constants'
import { message } from 'antd'
import {
  IActiveSubscription,
  setActiveSubscription,
  setActiveSubscriptionLoading
} from '../reducers/subscription.reducer'
import httpRequest from './httpRequest'

export const fetchScheduleTime = () => {
  const data: Record<string, string>[] = []

  ;[
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ].forEach((hour) => {
    ;['00', '15', '30', '45'].forEach((min) => {
      const key = `${hour}:${min}:00`
      const val = moment(`2022-01-01 ${key}`).format('hh:mm A')
      data.push({
        [key]: val
      })
    })
  })
}

export const openURLInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const downloadFileFromURL = (url: string): void => {
  if (!url) {
    return
  }

  try {
    const fileName = url.split('/').pop() || undefined
    saveAs(url, fileName)
  } catch (error) {
    message.error('Unable to download a file')
  }
}

export const showDateTime = (dt: string, utc = true) => {
  return utc
    ? moment(dt).utc().format(DATE_FORMAT)
    : moment(dt).format(DATE_FORMAT)
}

/**
 * Format Date time to human readable format
 *
 * @param  String date time
 * @return Formatted date time
 */
export const formatDateTime = (dateTimeString: string): string | null => {
  return momentTz(dateTimeString).isValid()
    ? momentTz(dateTimeString).tz(TIME_ZONE).format(DATE_FORMAT)
    : null
}

/**
 * Format Date to human readable format
 *
 * @param  String date time
 * @return Formatted date time
 */
export const formatDate = (dateString: string): string | null => {
  return moment(dateString).isValid()
    ? moment(dateString).format(SHORT_DATE_FORMAT)
    : null
}

/**
 * Verify if the token is expired or not
 * @param token - Your JWT
 * @returns boolean
 */
export function isTokenExpired(exp: number): boolean {
  let result = true

  if (exp) {
    const expirationDate: Date = new Date(0)
    expirationDate.setUTCSeconds(exp) // sets the expiration seconds
    // compare the expiration time and the current time
    result = expirationDate.valueOf() < new Date().valueOf()
  }

  return result
}

/**
 * Logout user from current session
 *
 * @return void
 */
export const logoutSession = (): void => {
  store.dispatch(clearToken())
  store.dispatch(clearSessionUser())
}

/**
 * Format number with currency
 *
 * @param  Number amount
 * @param  String currency code
 * @return formatted currency amount
 */
export const formatCurrency = (amount: number, currency = 'INR'): string => {
  // Check if the number is invalid
  amount = amount === Infinity ? 0 : amount

  return Number(amount || 0).toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currency
  })
}

/**
 * Format number with decimal
 *
 * @param  number Number
 * @return formatted number with decimal
 */
export const formatNumber = (amount: number): string => {
  return (amount || 0).toLocaleString()
}

/**
 * Parse the string Object to JSON
 *
 * @param  String json string
 * @return Record<string, any>
 */
export const objectParse = (str: string): Record<string, unknown> => {
  try {
    const tmpJSON = JSON.parse(str)
    if (typeof tmpJSON === 'string') {
      return JSON.parse(tmpJSON)
    } else {
      return tmpJSON
    }
  } catch (error) {
    return {}
  }
}

/**
 * Parse the string Array to Array
 *
 * @param  String json string
 * @return any
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ArrayParse = (str: string): any[] => {
  try {
    const tmpArray = JSON.parse(str)
    return Array.isArray(tmpArray) ? tmpArray : []
  } catch (error) {
    return []
  }
}

/**
 * Sleep thread for specified milliseconds
 *
 * @param  number milliseconds, Default 1000 milliseconds
 * @return Promise<unknown>
 */
export const sleepThread = (milliseconds?: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds || 1000))
}

/**
 * Stripe all HTML tags from string
 *
 * @param  String HTML string
 * @return String pure string
 */
export const stripHTMLTags = (htmlString: string) => {
  return (htmlString || '').replace(/<[^>]+>/g, '')
}

/**
 * Generate backend URL
 *
 * @param  String url
 * @return String full url
 */
export const generateBackendUrl = (string: string): string => {
  const apiEndPoint = process.env.REACT_APP_API_END_POINT_SETTLER || ''
  const tmpString = string.replace(apiEndPoint, '')

  console.log({ apiEndPoint })
  console.log({ tmpString })

  return `${apiEndPoint}${tmpString}`
}

/**
 * Generate random string
 *
 * @param  Number length
 * @return Random string
 */
export const randomString = (length = 10) => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

/**
 * Convert Object key value pair to URL query params
 *
 * @param Object Key Value pair object
 */
export const objectToQueryString = (object: any) => {
  Object.entries(object).map(([key, val]) => !val && delete object[key])

  if (Object.keys(object).length > 0) {
    return new URLSearchParams(object).toString()
  } else {
    return ''
  }
}

/**
 * Fetch Active Subscription and store in the redux
 *
 * @return void
 */
export const fetchActiveSubscription = async () => {
  store.dispatch(setActiveSubscriptionLoading(true))
  try {
    const { data } = await httpRequest('settler').get<{
      subscription: IActiveSubscription
    }>(`/subscription/active/scope:subscription`)

    store.dispatch(setActiveSubscription(data.subscription || {}))
  } catch (error) {
    message.error('Unable to fetch active subscription')
  } finally {
    store.dispatch(setActiveSubscriptionLoading(false))
  }
}
