import { combineReducers } from 'redux'

import notificationReducer from './notification.reducer'
import persistReducer from './persist.reducer'
import orderReducer from './order.reducer'
import sessionReducer from './session.reducer'
import socketIOReducer from './socketIO.reducer'
import platformReducer from './platform.reducer'
import accountSettingsReducer from './accountSettings.reducer'
import subscriptionReducer from './subscription.reducer'

const rootReducers = combineReducers({
  order: orderReducer,
  persist: persistReducer,
  session: sessionReducer,
  socket: socketIOReducer,
  platform: platformReducer,
  subscription: subscriptionReducer,
  notification: notificationReducer,
  accountSettings: accountSettingsReducer
})

export type RootReducersState = ReturnType<typeof rootReducers>
export default rootReducers
