import { combineReducers, createSlice } from '@reduxjs/toolkit'

interface IMeeshoPlatformList {
  id: string
  platform_id: string
  status: number
  meesho_user_id: string
  refresh_token: string
  cookie_data: string
  token: string
  name: string
  role: string
  valid: number
  device_id: string
  created: string
  registration_status: string
  identifier: string
  supplier_id: string
  supplier_identifiers: string
  supplier_identifier_to_id_mapping: string
}

const meeshoPlatformList = createSlice({
  name: 'meeshoPlatformList',
  initialState: [] as IMeeshoPlatformList[],
  reducers: {
    setMeeshoPlatformList: (state, { payload }) => {
      return payload
    }
  }
})

// Export actions
export const { setMeeshoPlatformList } = {
  ...meeshoPlatformList.actions
}

// Export reducers
export default combineReducers({
  meesho: meeshoPlatformList.reducer
})
