import { combineReducers, createSlice } from '@reduxjs/toolkit'

export type SocketConnectionState =
  | 'idle'
  | 'connecting'
  | 'connected'
  | 'error'

const initialState: SocketConnectionState = 'idle'

const socketConnectionStatus = createSlice({
  name: 'socketConnectionStatus',
  initialState: initialState as SocketConnectionState,
  reducers: {
    setSocketConnectionStatus: (state, { payload }) => {
      return payload
    }
  }
})

const socketConnectionMessage = createSlice({
  name: 'socketConnectionMessage',
  initialState: 'Connecting...',
  reducers: {
    setSocketConnectionMessage: (state, { payload }) => {
      return payload
    }
  }
})

// Export actions
export const { setSocketConnectionStatus, setSocketConnectionMessage } = {
  ...socketConnectionStatus.actions,
  ...socketConnectionMessage.actions
}

// Export reducers
export default combineReducers({
  status: socketConnectionStatus.reducer,
  message: socketConnectionMessage.reducer
})
