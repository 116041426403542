import { Spin } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor } from './reducers/store'
import AppRoute from './routes'
import useDevKeyword from './hooks/useDevKeyword'
// import DisableDevtool from 'disable-devtool'

const App = () => {
  // const disabled = !(process.env.REACT_APP_DISABLE_NETWORK_TAB === 'false')
  const { devKeywordFlag } = useDevKeyword()
  // TODO - prevent opening with cmd+shft+cw
  if (!devKeywordFlag) {
    // DisableDevtool()
  }

  return (
    <PersistGate
      loading={<Spin className="center" tip="Loading..." size="large" />}
      persistor={persistor}
    >
      <AppRoute />
    </PersistGate>
  )
}

export default App
