import { combineReducers, createSlice } from '@reduxjs/toolkit'

const orderInitialState: {
  tabName: string
} = {
  tabName: ''
}

const orderRefreshTab = createSlice({
  name: 'orders',
  initialState: orderInitialState,
  reducers: {
    triggerAutoRefreshOrderTab: (state, { payload }) => {
      return { ...state, tabName: payload }
    }
  }
})

// Export actions
export const { triggerAutoRefreshOrderTab } = {
  ...orderRefreshTab.actions
}

// Export reducers
export default combineReducers({
  autoRefresh: orderRefreshTab.reducer
})
