import type { TablePaginationConfig } from 'antd/es/table'

export const INFINITY = '∞'

export const PAYMENT_QR_CODE = `${process.env.REACT_APP_API_END_POINT_SETTLER}/assets/payment_qr_code.png`

export const PLATFORMS = [
  {
    value: 'meesho',
    label: 'Meesho'
  }
]

/**
 * Meesho Schedule types
 *
 */
export const MEESHO_PENDING_ORDERS_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c1' // Default Scheduler
export const MEESHO_SHIPPED_ORDERS_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c2' // Default Scheduler
export const MEESHO_CANCEL_ORDERS_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c3' // Default Scheduler
export const MEESHO_READY_TO_SHIP_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c6' // Default Scheduler
export const MEESHO_PAYMENT_RECONCILIATION_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c7' // Default Scheduler
export const MEESHO_RETURN_RECONCILIATION_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c8' // Default Scheduler
export const MEESHO_INVENTORY_PRICE_UPDATE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb8110' // Default Scheduler
export const MEESHO_SYNC_INVENTORY_SCHEDULE_TYPE_UUID =
  '5d499c0d-6100-ma64-m681-m47804eb81c9'

export const ADMIN_ALLOW_SCHEDULER = [
  MEESHO_CANCEL_ORDERS_SCHEDULE_TYPE_UUID,
  MEESHO_PAYMENT_RECONCILIATION_SCHEDULE_TYPE_UUID,
  MEESHO_RETURN_RECONCILIATION_SCHEDULE_TYPE_UUID,
  MEESHO_INVENTORY_PRICE_UPDATE_UUID
]

/**
 * OMS Glitch text which means the error is serious and need to look into
 *
 */
export const OMS_GLITCH = 'OMS_Glitch'

export const CURRENCY_SYMBOL = '₹'
export const TIME_ZONE = 'Asia/Kolkata'
export const REDUX_STORE_NAME = 'OMS-XDHR'
export const PAGE_SIZE = 20
export const PAGE_SIZE_100 = 100
export const PAGE_SIZE_2000 = 2000
export const PAGE_SIZE_5000 = 5000
export const PAGE_SIZE_LIMIT = 200
export const DATE_FORMAT = 'DD MMM, YYYY hh:mm A'
export const SHORT_DATE_FORMAT = 'DD MMM, YYYY'
export const MONTH_DAY_DATE_FORMAT = 'DD MMM'
export const MONTH_DAY_TIME_FORMAT = 'DD MMM, hh:mm A'
export const MONTH_DAY_TIME_SECOND_FORMAT = 'DD MMM, hh:mm:ss A'
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD'
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:00'
export const DATE_FORMATS = {
  DATE_TIME_SECOND: 'DD MMM, YYYY hh:mm:ss A',
  FILTER_FROM_MIN: 'YYYY-MM-DD HH:mm:00',
  FILTER_TO_MIN: 'YYYY-MM-DD HH:mm:59'
}

// Pages Sizes
export const PAGE_SIZES = {
  _20: 20,
  _50: 50,
  _100: 100,
  _2000: 2000,
  _5000: 5000,
  _LIMIT: 200
}

// Text const
export const SYSTEM_RUNNER_DATE_TEXT = 'This is a system runner date'

export const MEESHO_DELIVERY_PARTNER = [
  { id: 1, name: 'Delhivery' },
  { id: 2, name: 'Ecom Express' },
  { id: 3, name: 'Others' },
  { id: 4, name: 'Xpress Bees' },
  { id: 5, name: 'Shadowfax' },
  { id: 6, name: 'Blue Dart' },
  { id: 7, name: 'Jusda' },
  { id: 8, name: 'Load Share' },
  { id: 9, name: 'FedEx' },
  { id: 10, name: 'Wow' },
  { id: 18, name: 'ElasticRun' },
  { id: 19, name: 'Valmo' },
  { id: 20, name: 'DTDC' }
]

export const ORDER_STATUSES = [
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Ready to Ship',
    value: 'ready-to-ship'
  },
  {
    label: 'Shipped',
    value: 'shipped'
  },
  {
    label: 'Cancelled',
    value: 'cancelled'
  },
  {
    label: 'Ghost orders',
    value: 'ghost'
  }
]

export const DEFAULT_PAGINATION: TablePaginationConfig = {
  current: 1,
  total: 0,
  pageSize: 50
}

export const SCHEDULE_JOB_TIME = [
  { key: '00:00:00', value: '00:00 Morning' },
  { key: '00:15:00', value: '00:15 Morning' },
  { key: '00:30:00', value: '00:30 Morning' },
  { key: '00:45:00', value: '00:45 Morning' },
  { key: '01:00:00', value: '01:00 Morning' },
  { key: '01:15:00', value: '01:15 Morning' },
  { key: '01:30:00', value: '01:30 Morning' },
  { key: '01:45:00', value: '01:45 Morning' },
  { key: '02:00:00', value: '02:00 Morning' },
  { key: '02:15:00', value: '02:15 Morning' },
  { key: '02:30:00', value: '02:30 Morning' },
  { key: '02:45:00', value: '02:45 Morning' },
  { key: '03:00:00', value: '03:00 Morning' },
  { key: '03:15:00', value: '03:15 Morning' },
  { key: '03:30:00', value: '03:30 Morning' },
  { key: '03:45:00', value: '03:45 Morning' },
  { key: '04:00:00', value: '04:00 Morning' },
  { key: '04:15:00', value: '04:15 Morning' },
  { key: '04:30:00', value: '04:30 Morning' },
  { key: '04:45:00', value: '04:45 Morning' },
  { key: '05:00:00', value: '05:00 Morning' },
  { key: '05:15:00', value: '05:15 Morning' },
  { key: '05:30:00', value: '05:30 Morning' },
  { key: '05:45:00', value: '05:45 Morning' },
  { key: '06:00:00', value: '06:00 Morning' },
  { key: '06:15:00', value: '06:15 Morning' },
  { key: '06:30:00', value: '06:30 Morning' },
  { key: '06:45:00', value: '06:45 Morning' },
  { key: '07:00:00', value: '07:00 Morning' },
  { key: '07:15:00', value: '07:15 Morning' },
  { key: '07:30:00', value: '07:30 Morning' },
  { key: '07:45:00', value: '07:45 Morning' },
  { key: '08:00:00', value: '08:00 Morning' },
  { key: '08:15:00', value: '08:15 Morning' },
  { key: '08:30:00', value: '08:30 Morning' },
  { key: '08:45:00', value: '08:45 Morning' },
  { key: '09:00:00', value: '09:00 Morning' },
  { key: '09:15:00', value: '09:15 Morning' },
  { key: '09:30:00', value: '09:30 Morning' },
  { key: '09:45:00', value: '09:45 Morning' },
  { key: '10:00:00', value: '10:00 Morning' },
  { key: '10:15:00', value: '10:15 Morning' },
  { key: '10:30:00', value: '10:30 Morning' },
  { key: '10:45:00', value: '10:45 Morning' },
  { key: '11:00:00', value: '11:00 Morning' },
  { key: '11:15:00', value: '11:15 Morning' },
  { key: '11:30:00', value: '11:30 Morning' },
  { key: '11:45:00', value: '11:45 Morning' },
  { key: '12:00:00', value: '12:00 Noon' },
  { key: '12:15:00', value: '12:15 Noon' },
  { key: '12:30:00', value: '12:30 Noon' },
  { key: '12:45:00', value: '12:45 Noon' },
  { key: '13:00:00', value: '01:00 Noon' },
  { key: '13:15:00', value: '01:15 Noon' },
  { key: '13:30:00', value: '01:30 Noon' },
  { key: '13:45:00', value: '01:45 Noon' },
  { key: '14:00:00', value: '02:00 Noon' },
  { key: '14:15:00', value: '02:15 Noon' },
  { key: '14:30:00', value: '02:30 Noon' },
  { key: '14:45:00', value: '02:45 Noon' },
  { key: '15:00:00', value: '03:00 Noon' },
  { key: '15:15:00', value: '03:15 Noon' },
  { key: '15:30:00', value: '03:30 Noon' },
  { key: '15:45:00', value: '03:45 Noon' },
  { key: '16:00:00', value: '04:00 Noon' },
  { key: '16:15:00', value: '04:15 Noon' },
  { key: '16:30:00', value: '04:30 Noon' },
  { key: '16:45:00', value: '04:45 Noon' },
  { key: '17:00:00', value: '05:00 Noon' },
  { key: '17:15:00', value: '05:15 Noon' },
  { key: '17:30:00', value: '05:30 Noon' },
  { key: '17:45:00', value: '05:45 Noon' },
  { key: '18:00:00', value: '06:00 Evening' },
  { key: '18:15:00', value: '06:15 Evening' },
  { key: '18:30:00', value: '06:30 Evening' },
  { key: '18:45:00', value: '06:45 Evening' },
  { key: '19:00:00', value: '07:00 Evening' },
  { key: '19:15:00', value: '07:15 Evening' },
  { key: '19:30:00', value: '07:30 Evening' },
  { key: '19:45:00', value: '07:45 Evening' },
  { key: '20:00:00', value: '08:00 Evening' },
  { key: '20:15:00', value: '08:15 Evening' },
  { key: '20:30:00', value: '08:30 Evening' },
  { key: '20:45:00', value: '08:45 Evening' },
  { key: '21:00:00', value: '09:00 Night' },
  { key: '21:15:00', value: '09:15 Night' },
  { key: '21:30:00', value: '09:30 Night' },
  { key: '21:45:00', value: '09:45 Night' },
  { key: '22:00:00', value: '10:00 Night' },
  { key: '22:15:00', value: '10:15 Night' },
  { key: '22:30:00', value: '10:30 Night' },
  { key: '22:45:00', value: '10:45 Night' },
  { key: '23:00:00', value: '11:00 Night' },
  { key: '23:15:00', value: '11:15 Night' },
  { key: '23:30:00', value: '11:30 Night' },
  { key: '23:45:00', value: '11:45 Night' }
]

/**
 * Statistics types for Dashboard
 *
 */
export const DASHBOARD_STATISTIC_TYPE = {
  TODAY_RETURN: 'TODAY_RETURN',
  TOP_SELLING_SKU_WEEK: 'TOP_SELLING_SKU_WEEK',
  BOTTOM_SELLING_SKU_WEEK: 'BOTTOM_SELLING_SKU_WEEK',
  TOP_SELLING_SKU_MONTH: 'TOP_SELLING_SKU_MONTH',
  BOTTOM_SELLING_SKU_MONTH: 'BOTTOM_SELLING_SKU_MONTH',
  TOP_SELLING_SKU_PREV_WEEK: 'TOP_SELLING_SKU_PREV_WEEK',
  BOTTOM_SELLING_SKU_PREV_WEEK: 'BOTTOM_SELLING_SKU_PREV_WEEK',
  TOP_SELLING_SKU_PREV_MONTH: 'TOP_SELLING_SKU_PREV_MONTH',
  BOTTOM_SELLING_SKU_PREV_MONTH: 'BOTTOM_SELLING_SKU_PREV_MONTH'
}

/**
 * Notification Types
 *
 */
export const NOTIFICATION_TYPES = [
  { value: 1, label: 'System', tagColor: 'magenta' },
  { value: 2, label: 'Broadcast', tagColor: 'processing' }
]

/**
 * Model Sizes
 *
 */
export const MODAL_SIZE = {
  XXL: 1400,
  XL: 1200,
  LG: 900,
  MD: 700,
  SM: 500
}

/**
 * All E-Comm end points
 *
 */
export const MEESHO_END_POINT = 'https://supplier.meesho.com/'

/**
 * Meesho order status
 *
 */
export const MEESHO_ORDER_STATUS = {
  ON_HOLD: 0,
  PENDING: 1,
  READY_TO_SHIP: 3,
  SHIPPED: 4,
  CANCELLED: 5,
  GHOST: 99
}

/**
 * Packages Type
 *
 */
export const PACKAGES_TYPES = {
  DEMO: 1,
  REGULAR: 2,
  DAYS: 3
}
