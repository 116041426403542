import { lazy, Suspense } from 'react'
import { Result, Spin } from 'antd'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import { AdminRoutes, LoginRoutes, UserRoutes } from './app.routes'
import OrderUsagesHistory from '../Pages/subscription/OrderUsagesHistory'

const Login = lazy(() => import('../Pages/Auth/Login'))
const ResetPassword = lazy(() => import('../Pages/Auth/ResetPassword'))
const AccountSettings = lazy(() => import('../Pages/accountSettings'))
const OrdersList = lazy(() => import('../Pages/Meesho/orders'))
const Dashboard = lazy(() => import('../Pages/dashboard'))
const Platforms = lazy(() => import('../Pages/platforms'))
const RouteMiddleware = lazy(() => import('./RouteMiddleware'))
const Notification = lazy(() => import('../Pages/notifications'))
const MyPurchase = lazy(() => import('../Pages/subscription/MyPurchase'))
const Packages = lazy(() => import('../Pages/subscription/Packages'))
const OrderUsages = lazy(() => import('../Pages/subscription/OrderUsages'))
const ScheduledRunners = lazy(() => import('../Pages/scheduledRunners'))
const MeeshoInventory = lazy(
  () => import('../Pages/inventories/MeeshoInventory')
)
const ReturnOrdersReport = lazy(
  () => import('../Pages/reports/ReturnOrdersReport')
)
const ProfitReport = lazy(() => import('../Pages/reports/ProfitReport'))
const ReturnedScan = lazy(() => import('../Pages/returnedScan'))
const AdminDashboard = lazy(() => import('../Pages/Admin/adminDashboard'))
const UserManagement = lazy(() => import('../Pages/Admin/adminUserManagement'))
const UpsertUser = lazy(
  () => import('../Pages/Admin/adminUserManagement/upsertUser')
)
const AdminScheduledRunners = lazy(
  () => import('../Pages/Admin/adminScheduledRunners')
)
const AdminScheduledRunnersHistory = lazy(
  () => import('../Pages/Admin/adminScheduledRunnersHistory')
)
const Settings = lazy(() => import('../Pages/appSettings'))
const SystemLogs = lazy(() => import('../Pages/Admin/adminSystemLogs'))
const AdminPackages = lazy(() => import('../Pages/Admin/adminPackages'))
const AdminPurchaseHistory = lazy(
  () => import('../Pages/Admin/adminPurchaseHistory')
)
const AdminSendNotifications = lazy(
  () => import('../Pages/Admin/adminSendNotifications')
)
const AdminSettings = lazy(() => import('../Pages/Admin/adminSettings'))

const AppRoute = () => {
  return (
    <Suspense
      fallback={<Spin className="center" tip="Hang Tight..." size="large" />}
    >
      <BrowserRouter>
        <Routes>
          {/* Public routes */}
          <Route element={<RouteMiddleware route="loggedIn" />}>
            <Route path={LoginRoutes.login} element={<Login />} />
            <Route
              path={LoginRoutes.resetPassword}
              element={<ResetPassword />}
            />
          </Route>

          {/* Customer Routes */}
          <Route element={<RouteMiddleware route="stardom" />}>
            <Route
              path={UserRoutes.accountSettings.path}
              element={<AccountSettings />}
            />
            <Route path={UserRoutes.dashboard.path} element={<Dashboard />} />
            <Route path={UserRoutes.platforms.path} element={<Platforms />} />
            <Route
              path={UserRoutes.scheduledRunners.path}
              element={<ScheduledRunners />}
            />
            <Route
              path={UserRoutes.meeshoInventories.path}
              element={<MeeshoInventory />}
            />
            <Route
              path={UserRoutes.meeshoPendingOrders.path}
              element={<OrdersList />}
            />
            <Route
              path={UserRoutes.meeshoRTDOrders.path}
              element={<OrdersList />}
            />
            <Route
              path={UserRoutes.meeshoShippedOrders.path}
              element={<OrdersList />}
            />
            <Route
              path={UserRoutes.meeshoCancelledOrders.path}
              element={<OrdersList />}
            />
            <Route
              path={UserRoutes.meeshoGhostOrders.path}
              element={<OrdersList />}
            />
            <Route path={UserRoutes.appSettings.path} element={<Settings />} />
            <Route
              path={UserRoutes.returnOrdersReport.path}
              element={<ReturnOrdersReport />}
            />
            <Route
              path={UserRoutes.profitReport.path}
              element={<ProfitReport />}
            />
            <Route
              path={UserRoutes.returnedScan.path}
              element={<ReturnedScan />}
            />
            <Route
              path={UserRoutes.notifications.path}
              element={<Notification />}
            />
            <Route
              path={UserRoutes.subscriptionPurchase.path}
              element={<MyPurchase />}
            />
            <Route
              path={UserRoutes.subscriptionPackages.path}
              element={<Packages />}
            />
            <Route
              path={UserRoutes.orderUsages.path}
              element={<OrderUsages />}
            />
            <Route
              path={UserRoutes.orderUsagesHistory.path}
              element={<OrderUsagesHistory />}
            />
          </Route>

          {/* Admin Routes */}
          <Route element={<RouteMiddleware route="superstardom" />}>
            <Route
              path={AdminRoutes.dashboard.path}
              element={<AdminDashboard />}
            />
            <Route
              path={AdminRoutes.userManagement.path}
              element={<UserManagement />}
            />
            <Route path={AdminRoutes.addUser.path} element={<UpsertUser />} />
            <Route
              path={AdminRoutes.scheduledRunners.path}
              element={<AdminScheduledRunners />}
            />
            <Route
              path={AdminRoutes.scheduledRunnersHistory.path}
              element={<AdminScheduledRunnersHistory />}
            />
            <Route
              path={AdminRoutes.systemLogFiles.path}
              element={<SystemLogs />}
            />
            <Route
              path={AdminRoutes.packages.path}
              element={<AdminPackages />}
            />
            <Route
              path={AdminRoutes.purchaseHistory.path}
              element={<AdminPurchaseHistory />}
            />
            <Route
              path={AdminRoutes.sendNotifications.path}
              element={<AdminSendNotifications />}
            />
            <Route
              path={AdminRoutes.settings.path}
              element={<AdminSettings />}
            />
          </Route>

          {/* Not Found */}
          <Route
            path="*"
            element={
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to="/">Go back</Link>}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default AppRoute
