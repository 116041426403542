import { combineReducers, createSlice } from '@reduxjs/toolkit'

export type PendingOrderGroupByType = 'default' | 'product_sku'
export type RTSOrderGroupByType =
  | 'default'
  | 'carrier_id'
  | 'order_charged_date'

const ordersInitialState: {
  pendingOrdersGroupBy: PendingOrderGroupByType
  RTSOrdersGroupBy: RTSOrderGroupByType
} = {
  pendingOrdersGroupBy: 'default',
  RTSOrdersGroupBy: 'default'
}

const orderSettings = createSlice({
  name: 'presist/order-settings',
  initialState: ordersInitialState,
  reducers: {
    setPendingOrdersGroupBy: (state, { payload }) => {
      return { ...state, pendingOrdersGroupBy: payload }
    },
    setRTSOrdersGroupBy: (state, { payload }) => {
      return { ...state, RTSOrdersGroupBy: payload }
    }
  }
})

const playSoundSetting = createSlice({
  name: 'presist/play-sound',
  initialState: false,
  reducers: {
    setPlaySoundSetting: (state, { payload }) => {
      return payload || false
    }
  }
})

const devKeyword = createSlice({
  name: 'presist/dev-keyword',
  initialState: '',
  reducers: {
    setDevKeywordFlag: (state, { payload }) => {
      return payload || ''
    }
  }
})

const localApiEndPoint = createSlice({
  name: 'presist/local-api-endpoint',
  initialState: process.env.REACT_APP_API_END_POINT_SETTLER,
  reducers: {
    setLocalApiEndPoint: (state, { payload }) => {
      return payload || ''
    }
  }
})

// Export actions
export const {
  setPendingOrdersGroupBy,
  setRTSOrdersGroupBy,
  setPlaySoundSetting,
  setDevKeywordFlag,
  setLocalApiEndPoint
} = {
  ...orderSettings.actions,
  ...playSoundSetting.actions,
  ...devKeyword.actions,
  ...localApiEndPoint.actions
}

// Export reducers
export default combineReducers({
  orderSettings: orderSettings.reducer,
  playSound: playSoundSetting.reducer,
  devKeyword: devKeyword.reducer,
  localApiEndPoint: localApiEndPoint.reducer
})
