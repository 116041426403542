import axios from 'axios'
import store from '../reducers/store'
import { logoutSession } from './global.helper'
import { message as promptMessage } from 'antd'

export type httpEnvType = 'pinggy' | 'settler'

/**
 * Config the message to display only once when receive many error
 *
 */
promptMessage.config({ maxCount: 1 })

/**
 * Export a default instance for requesting an APIs
 *
 * This instance includes auth Header
 */
const httpRequest = (env: httpEnvType = 'settler') => {
  const token = store.getState()?.session?.token || ''

  const serverEndPoint =
    env === 'pinggy'
      ? process.env.REACT_APP_API_END_POINT_PINGGY
      : process.env.REACT_APP_API_END_POINT_SETTLER

  const httpRequest = axios.create({
    baseURL: serverEndPoint,
    headers: {
      'oms-proxy': token
    },
    timeout: 120000 // 1 minute
  })

  // Watch every response received from the server
  httpRequest.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        // message.error('Unauthorized')
        logoutSession()
      } else if (error.response.status == 426) {
        const { message, prompt } = error.response.data

        if (prompt === 'message') {
          promptMessage.error(message)
        }
      }

      return Promise.reject(error)
    }
  )

  return httpRequest
}

export default httpRequest
