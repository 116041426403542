import { combineReducers, createSlice } from '@reduxjs/toolkit'

export enum ACCOUNT_SETTINGS_LOADING {
  SAVE_USER_PROFILE = 'SAVE_USER_PROFILE',
  NULL = ''
}

const accountSettingsLoading = createSlice({
  name: 'accountSettings/loading',
  initialState: '' as ACCOUNT_SETTINGS_LOADING,
  reducers: {
    setAccountSettingsLoading: (state, { payload }) => {
      return payload
    }
  }
})

// Export actions
export const { setAccountSettingsLoading } = {
  ...accountSettingsLoading.actions
}

// Export reducers
export default combineReducers({
  loading: accountSettingsLoading.reducer
})
