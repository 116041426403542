export const LoginRoutes = {
  login: '/login',
  resetPassword: '/reset-password/:token'
}

export const UserRoutes = {
  // Common routes
  accountSettings: {
    path: '/account/settings',
    openKey: 'account-settings',
    activeKey: 'account-settings'
  },
  dashboard: {
    path: '/',
    openKey: 'dashboard',
    activeKey: 'dashboard'
  },
  platforms: {
    path: '/platforms',
    openKey: 'platforms',
    activeKey: 'platforms'
  },
  scheduledRunners: {
    path: '/scheduled-runners',
    openKey: 'scheduledRunners',
    activeKey: 'scheduledRunners'
  },
  returnOrdersReport: {
    path: '/reports/return-orders',
    openKey: 'reportMenu',
    activeKey: 'returnOrdersReport'
  },
  profitReport: {
    path: '/reports/profit',
    openKey: 'reportMenu',
    activeKey: 'profitReport'
  },
  appSettings: {
    path: '/app/settings',
    openKey: 'app-settings',
    activeKey: 'app-settings'
  },
  subscription: {
    path: '/subscription',
    openKey: 'subscription',
    activeKey: 'subscription'
  },
  subscriptionPurchase: {
    path: '/subscription/purchase',
    openKey: 'subscription',
    activeKey: 'subscriptionPurchase'
  },
  subscriptionPackages: {
    path: '/subscription/packages',
    openKey: 'subscription',
    activeKey: 'subscriptionPackages'
  },
  orderUsages: {
    path: '/subscription/order-usages',
    openKey: 'subscription',
    activeKey: 'orderUsages'
  },
  orderUsagesHistory: {
    path: '/subscription/order-usages/:id/history',
    openKey: 'subscription',
    activeKey: 'orderUsages',
    generateURL: (id: number | string) =>
      `/subscription/order-usages/${id}/history`
  },
  notifications: {
    path: `/notifications`,
    openKey: 'notification',
    activeKey: 'notification'
  },

  // Meesho routes
  meeshoPendingOrders: {
    path: '/meesho/orders/pending',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoOrders'
  },
  meeshoRTDOrders: {
    path: '/meesho/orders/ready-to-dispatch',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoOrders'
  },
  meeshoShippedOrders: {
    path: '/meesho/orders/shipped',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoOrders'
  },
  meeshoCancelledOrders: {
    path: '/meesho/orders/cancelled',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoOrders'
  },
  meeshoGhostOrders: {
    path: '/meesho/orders/ghost',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoOrders'
  },
  meeshoInventories: {
    path: '/meesho/inventories',
    openKey: 'meeshoMenu',
    activeKey: 'meeshoInventories'
  },
  returnedScan: {
    path: '/returned-scan',
    openKey: 'returnScanMenu',
    activeKey: 'returnScan'
  }

  // Flipkart routes
}

const ADMIN_PREFIX = 'admin'
export const AdminRoutes = {
  dashboard: {
    path: `/${ADMIN_PREFIX}`,
    openKey: 'dashboard',
    activeKey: 'dashboard'
  },
  userManagement: {
    path: `/${ADMIN_PREFIX}/users`,
    openKey: 'userManagement',
    activeKey: 'userManagement'
  },
  addUser: {
    path: `/${ADMIN_PREFIX}/users/add`,
    openKey: 'userManagement',
    activeKey: 'userManagement'
  },
  scheduledRunners: {
    path: `/${ADMIN_PREFIX}/scheduled/runners`,
    openKey: 'scheduledRunners',
    activeKey: 'scheduledRunners'
  },
  scheduledRunnersHistory: {
    path: `/${ADMIN_PREFIX}/scheduled/runners/history`,
    openKey: 'scheduledRunnersHistory',
    activeKey: 'scheduledRunnersHistory'
  },
  systemLogFiles: {
    path: `/${ADMIN_PREFIX}/system-logs`,
    openKey: 'logFiles',
    activeKey: 'logFiles'
  },
  sendNotifications: {
    path: `/${ADMIN_PREFIX}/send-notifications`,
    openKey: 'sendNotifications',
    activeKey: 'sendNotifications'
  },
  packages: {
    path: `/${ADMIN_PREFIX}/packages`,
    openKey: 'packages',
    activeKey: 'packages'
  },
  purchaseHistory: {
    path: `/${ADMIN_PREFIX}/purchase-history`,
    openKey: 'purchaseHistory',
    activeKey: 'purchaseHistory'
  },
  settings: {
    path: `/${ADMIN_PREFIX}/settings`,
    openKey: 'settings',
    activeKey: 'settings'
  }
}
