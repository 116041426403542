import { combineReducers, createSlice } from '@reduxjs/toolkit'

const drawerInitialState: {
  runningActionCount: number
  visible: boolean
  autoRefresh: boolean
  autoRefreshAdvance: boolean
} = {
  runningActionCount: 0,
  visible: false,
  autoRefresh: false,
  autoRefreshAdvance: false
}

const drawer = createSlice({
  name: 'notification/show-drawer',
  initialState: drawerInitialState,
  reducers: {
    toggleDrawerVisible: (state, { payload }) => {
      return { ...state, visible: payload }
    },
    setNotificationCount: (state, { payload }) => {
      return { ...state, runningActionCount: payload }
    },
    toggleDrawerAutoRefresh: (state, { payload }) => {
      return { ...state, autoRefresh: payload }
    },
    triggerRefreshAdvance: (state, { payload }) => {
      return { ...state, autoRefreshAdvance: payload }
    }
  }
})

// Export actions
export const {
  toggleDrawerVisible,
  setNotificationCount,
  toggleDrawerAutoRefresh,
  triggerRefreshAdvance
} = {
  ...drawer.actions
}

// Export reducers
export default combineReducers({
  drawer: drawer.reducer
})
