/* eslint-disable react-hooks/exhaustive-deps */
import { SyncOutlined } from '@ant-design/icons'
import { Button, Card, Image, Popover, Spin, Table, message } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PAGE_SIZES } from '../../helpers/constants'
import { formatDate, formatDateTime } from '../../helpers/global.helper'
import httpRequest from '../../helpers/httpRequest'
import {
  IOrderUsages,
  IOrderUsagesHistory,
  IOrderUsagesHistoryFilter
} from './subscription.types'

const pageTitle = 'Order Usages History'

const defaultFilter = {
  page: 1,
  historyId: 0
}

const OrderUsagesHistory = () => {
  const params = useParams()
  const [filter, setFilter] = useState<IOrderUsagesHistoryFilter>({
    ...defaultFilter,
    historyId: params.id || '0'
  })
  const [loading, setLoading] = useState<'fetch' | null>(null)
  const [orderUsagesData, setOrderUsagesData] = useState<IOrderUsages>()
  const [orderUsagesHistoryData, setOrderUsagesHistoryData] = useState<
    IOrderUsagesHistory[]
  >([])
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false)

  const columns: ColumnsType<IOrderUsagesHistory> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (value, row) => (
        <Popover
          title={value}
          overlayClassName="popover-image"
          content={
            <div className="d-flex justify-content-end">
              <Image src={String(row.image || '')} height={200} />
            </div>
          }
        >
          <span className="pointer ellipsis d-block w-400-px">{value}</span>
        </Popover>
      )
    },
    {
      title: 'Product SKU',
      dataIndex: 'product_sku'
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      align: 'center'
    },
    {
      title: 'Suborder Number',
      dataIndex: 'suborder_number'
    },
    {
      title: 'Status',
      dataIndex: 'live_order_status'
    },
    {
      title: 'Charged At',
      dataIndex: 'order_charged_date',
      width: '13%',
      render: (value) => formatDateTime(value)
    },
    {
      title: 'Places At',
      dataIndex: 'created_iso',
      width: '13%',
      render: (value) => formatDateTime(value)
    },
    {
      title: 'System Created',
      dataIndex: 'created_at',
      width: '13%',
      render: (value) => formatDateTime(value)
    }
  ]

  // Fetch order usages data
  useEffect(() => {
    fetchOrderUsagesData()
  }, [filter])

  const fetchOrderUsagesData = async () => {
    setLoading('fetch')
    try {
      const { data } = await httpRequest().post<{
        header: IOrderUsages
        rows: IOrderUsagesHistory[]
      }>(`/order-usages/scope:fetch/history`, { ...filter })

      // Show the loading button if there are more data to load
      if (data.rows.length >= PAGE_SIZES._100) {
        setShowLoadMore(true)
      } else {
        setShowLoadMore(false)
      }

      setOrderUsagesData(data.header || {})

      if (filter.page === 1) {
        setOrderUsagesHistoryData(data.rows || [])
      } else {
        setOrderUsagesHistoryData((prevState) => [...prevState, ...data.rows])
      }
    } catch (error) {
      message.error('Unable to fetch history details')
    } finally {
      setLoading(null)
    }
  }

  return (
    <Card title={pageTitle}>
      <div className="flex-row align-items-center gap-3 pb-2 mb-2 ml-2 border-bottom-2">
        <label>
          Platform Name :{' '}
          {loading === 'fetch' ? (
            <SyncOutlined spin />
          ) : (
            orderUsagesData?.platform.platformGroup.platform_name
          )}
        </label>
        <label>
          Account Name :{' '}
          {loading === 'fetch' ? (
            <SyncOutlined spin />
          ) : (
            orderUsagesData?.platform.meeshoAccount.account_name
          )}
        </label>
        <label>
          Charged Date :{' '}
          {loading === 'fetch' ? (
            <SyncOutlined spin />
          ) : (
            formatDate(orderUsagesData?.charged_date || '')
          )}
        </label>
        <label>
          Order Count :{' '}
          {loading === 'fetch' ? (
            <SyncOutlined spin />
          ) : (
            orderUsagesData?.order_count
          )}
        </label>
      </div>
      <Table
        className="tbl-compact striped-rows"
        size="small"
        columns={columns}
        rowKey={(record) => record.id}
        loading={loading === 'fetch'}
        dataSource={orderUsagesHistoryData}
        pagination={false}
      />
      {showLoadMore && (
        <div className="text-center">
          <Button
            size="small"
            type="link"
            className="mt-2"
            onClick={() => {
              if (loading !== 'fetch') {
                setFilter((prevState) => ({
                  ...prevState,
                  page: prevState.page + 1
                }))
              }
            }}
          >
            {loading === 'fetch' ? <Spin /> : 'Load more'}
          </Button>
        </div>
      )}
    </Card>
  )
}

export default OrderUsagesHistory
