import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import App from './App'
import store from './reducers/store'
import reportWebVitals from './reportWebVitals'
import './styles/main.css'

const root = ReactDOM.createRoot(
  document.getElementById('oms-access-forbidden') as HTMLElement
)
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
