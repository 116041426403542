import { combineReducers, createSlice } from '@reduxjs/toolkit'
import { SUBSCRIPTION_DISCOUNT_TYPE } from '../types/common.type'
import { PACKAGES_TYPES, SHORT_DATE_FORMAT } from '../helpers/constants'
import moment from 'moment'
import { formatNumber } from '../helpers/global.helper'

export interface IActiveSubscription {
  balance_order_count: number
  package_expire_date: string
  package: {
    package_id: string
    package_name: string
    package_type: number
    base_price: number
    discount_type: SUBSCRIPTION_DISCOUNT_TYPE
    discount: number
    tax_gst: number
    order_count: number | null
    package_days: number | null
    final_price: number
  }
  packageExpired: boolean
  packageSignal: boolean
  displayText: string
}

const activeSubscription = createSlice({
  name: 'subscription/package',
  initialState: {} as IActiveSubscription,
  reducers: {
    setActiveSubscription: (state, { payload }) => {
      let displayText = 'Recharge'
      if (payload?.package?.package_type == PACKAGES_TYPES.DAYS) {
        displayText = `Unlimited till ${moment(
          payload.package_expire_date
        ).format(SHORT_DATE_FORMAT)}`
      } else if (
        [PACKAGES_TYPES.DEMO, PACKAGES_TYPES.REGULAR].includes(
          payload?.package?.package_type
        )
      ) {
        displayText = `${formatNumber(payload.balance_order_count)} Orders`
      }

      return {
        ...payload,
        displayText: displayText
      }
    }
  }
})

// Active Subscription Loading state
const activeSubscriptionLoading = createSlice({
  name: 'subscription/loading',
  initialState: true,
  reducers: {
    setActiveSubscriptionLoading: (state, { payload }) => {
      return payload
    }
  }
})

// Export actions
export const { setActiveSubscription, setActiveSubscriptionLoading } = {
  ...activeSubscription.actions,
  ...activeSubscriptionLoading.actions
}

// Export reducers
export default combineReducers({
  activePackageInfo: activeSubscription.reducer,
  loading: activeSubscriptionLoading.reducer
})
