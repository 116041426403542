import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from '.'
import { REDUX_STORE_NAME } from '../helpers/constants'

const persistConfig = {
  key: REDUX_STORE_NAME,
  storage,
  whitelist: ['persist', 'session']
}

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types for serializer
        ignoredActions: ['persist/PERSIST']
      }
    }),
  devTools: process.env.REACT_APP_REDUX_DEV_TOOL === 'true' ? true : false
})

export const persistor = persistStore(store)

export default store
